$discussion-messaging-icon-size: 18px;


// New discussion message component - to type and send a new discussion message
.new-discussion-message {
  display: flex;
  align-items: flex-end; // Vertically align the textarea and button bottom
  margin-bottom: 2em;
  padding: 0.75em;
  border: 1px solid #DBDBDB;
}

.new-discussion-message__input {
  flex: 1;
  resize: vertical; // Allow the textarea to be increased in height
}

.new-discussion-message__button {
  margin-left: 0.6em;
  font-size: $discussion-messaging-icon-size;
}


 // Discussion message component - for each discussion message
.discussion-message {
  display: flex;
  margin-bottom: 2em;
}

.discussion-message__icon-container {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.6em;
  border: 1px solid #D1D1D1;
}

.discussion-message__icon {
  font-size: $discussion-messaging-icon-size;
  color: #999999;
}

.discussion-message__name {
  padding-top: 2px; // Vertically align the name and date with the icon container
  font-size: 0.9em;
  font-weight: bolder;
  color: #717171;
}

.discussion-message__date {
  margin-bottom: 0.5em;
  font-size: 0.9em;
  color: $light-text-color;
}

.discussion-message__content {
  color: #222222;
  white-space: pre-line; // Preserve new lines in the contents
}


// Discussion messaging component - shows discussion message and discussion message component
.discussion-messaging__no-messages {
  margin-bottom: 0.7em;
  text-align: center;
  font-size: 1.1em;
  font-weight: bold;
  color: #5D676C;
}

.discussion-messaging__description {
    padding: 0 10px; // Align content with the new discussion message component box border
    color: #5D676C;
}
