﻿// Mirroring the margin and padding spacing classes in Bootstrap 4
// https://getbootstrap.com/docs/4.1/utilities/spacing/

$spacer: 1rem;

$size-1-multiplier: 0.25;
$size-2-multiplier: 0.5;
$size-3-multiplier: 1;
$size-4-multiplier: 1.5;
$size-5-multiplier: 3;
$size-6-multiplier: 4.5;
$size-7-multiplier: 9;

.mt-0 {
    margin-top: 0;
}

.mt-1 {
    margin-top: $spacer * $size-1-multiplier;
}

.mt-2 {
    margin-top: $spacer * $size-2-multiplier;
}

.mt-3 {
    margin-top: $spacer * $size-3-multiplier;
}

.mt-6 {
    margin-top: $spacer * $size-6-multiplier;
}

.mt-7 {
    margin-top: $spacer * $size-7-multiplier;
}

.mb-1 {
    margin-bottom: $spacer * $size-1-multiplier;
}

.mb-3 {
    margin-bottom: $spacer * $size-3-multiplier;
}

.mb-6 {
    margin-bottom: $spacer * $size-6-multiplier;
}

.mb-7 {
    margin-bottom: $spacer * $size-7-multiplier;
}

.ml-1 {
    margin-left: $spacer * $size-1-multiplier;
}

.mr-1 {
    margin-right: $spacer * $size-1-multiplier;
}

.mr-2 {
    margin-right: $spacer * $size-2-multiplier;
}

.px-1 {
    padding-left: $spacer * $size-1-multiplier;
    padding-right: $spacer * $size-1-multiplier;
}

.px-3 {
    padding-left: $spacer * $size-3-multiplier;
    padding-right: $spacer * $size-3-multiplier;
}

.px-5 {
    padding-left: $spacer * $size-5-multiplier;
    padding-right: $spacer * $size-5-multiplier;
}

.py-1 {
    padding-top: $spacer * $size-1-multiplier;
    padding-bottom: $spacer * $size-1-multiplier;
}

.py-3 {
    padding-top: $spacer * $size-3-multiplier;
    padding-bottom: $spacer * $size-3-multiplier;
}

.no-sm-x-padding {
    // On xs and sm devices, remove left and right padding to make as much use of the viewport as possible
    @media (max-width: $screen-sm-max) {
        padding-left: 0;
        padding-top: 0;
    }
}
