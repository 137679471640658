// Request list component - displays a list of requests
.request-list__reference-number {
  font-size: 1.2em;
}

.request-list__secondary-value {
  font-size: 0.9em;
  color: #666666;
}

// If there is no assigned user / group, we show the text "Unassigned" and want this "greyed out"
.request-list__assigned-to--empty {
  color: #757575;
}

.request-list__inline-text-icon {
  margin-right: 2px;

  // Tone the icon colour down as a filled icon in standard text color is too domineering
  color: $icon-with-text-color;
}

.request-list__reference-container {
  // Ensure the container is only as wide as the reference number text. Then when the count icons and
  // text are displayed below, they are centered relative to the reference number text an not the table cell
  display: inline-block;
  text-align: center;
}

.request-list__counts-container {
  font-size: 1.3em;
}

.request-list__counts-icon {
  // Tone the icon colour down as a filled icon in standard text color is too domineering
  color: $icon-with-text-color;
}

.request-list__ellipsis-button-group {
    padding: 5px 12px; // Make the button a good sizes touch target and roughly square
    background: none;
    border: none;

    &:hover {
        background-color: #DEDEDE;
    }
}
