﻿.business-user-details-form {
    padding-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;

    .form-title {
        margin-bottom: 20px;
    }

    .horizontal-line {
        margin-top: 5px;
        margin-bottom: 5px
    }

    .checkbox-group {
        margin-top: 5px;
        margin-bottom: 5px;

        .checkbox-label {
            width: 100%
        }

        .checkbox-label-text {
            font-weight: normal;
            margin-left: 5px;
        }

        .checkbox-label-icon {
            margin-right: 5px;
            color: $help-icon-color;
        }
    }

    .group-ul-list {
        list-style-type: none;

        li {
            margin-bottom: 10px;
        }
    }

    .action-button-right-margin {
        margin-right: 5px;
    }
}
