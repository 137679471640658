﻿
// OpenLayers Overrides (appears OL expects a larger base font size than BS3's 14px)
.ol-attribution ul {
	font-size: 1rem; // Make this the root page font size as text was too small to read
}

.ol-attribution.ol-uncollapsible {
	height: 1.4em; // Allow more height for the attribution area given larger font size
}

.ol-zoom {
	font-size: 1.6rem; // Use a larger font size so buttons are a "normal" size
}


// Custom map styles
.map {
	border: 1px solid #DAD2D2;
	height: $default-map-height;
}

.map-popup-default {
	position: absolute;
	background-color: white;
	padding: 5px;
	border-radius: 10px;
	border: 1px solid #cccccc;
	bottom: 12px;
	left: -80px;
	min-width:200px;
}
.map-popup {
	@extend .map-popup-default;
	left: -50px;
    min-width:200px;
	&.disabled{display: none;}
	&.rectangle{
		border-radius: 0;
		min-width:200px;
	}
	.popup-scroll{
		height: 140px;
		overflow-y: scroll;
	}
    .heading{
        color: #999;
        font-size: 12px;
    }
}

.map-popup-closer {
	text-decoration: none;
	position: absolute;
	top: 2px;
	right: 8px;
	z-index: 300;
	&:hover{text-decoration: none;}
}
.map-popup-closer:after {
	content: "x";
	font-weight: bold;
}
