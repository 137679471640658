﻿
.action-buttons-header {
    float: right;
}

// This is a modifier for the class "action-buttons-header" and must be used in conjunction with this class
.action-buttons-header--edit-screen {
    // On edit pages on larger viewports, we show a thick page border so push the buttons down below this border
    @media (min-width: $screen-sm-min) {
        margin-top: 20px;
    }
}
