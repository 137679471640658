﻿
@media print {

    // If someone is printing the page, don't display the top navbar banner
    .navbarbanner {
        display: none;
    }

    // Ensure the grey border is displayed correctly when the navbar is hidden
    .form-container {
        margin-top: auto;
    }
}