//
// Progress bars
// --------------------------------------------------

// Outer container
.progress {
  height: 8px; // Reduce the height of the progress bar be more compact
  margin-top: 1px;
  margin-bottom: 0;
}
