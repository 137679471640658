.dual-listbox {
    margin-bottom: 20px;

    .dual-listbox-filter-title {
        margin-bottom: 15px;
    }

    .dual-listbox-buttons {
        display: flex;
        justify-content: center;
    }

    .dual-listbox-available,
    .dual-listbox-assigned {
        border: 1px solid #DEDEDE;
        height: 394px;
        overflow-y: auto;
        padding: 0;
        margin-top: 0;
    }

    .dual-listbox-assigned-item {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .left-content {
            flex: 1;
        }

        .item-checkbox {
            vertical-align: middle;
            margin-top: auto;
            margin-bottom: auto;
        }

        .icon-tooltip {
            vertical-align: middle;
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 5px;
        }
    }

    .dual-listbox-available-item,
    .dual-listbox-assigned-item {
        padding: 10px;
        cursor: pointer;
        border-bottom: 1px solid #efefef;
        transition: background 0.2s ease;

        &:focus:not(.active),
        &:hover:not(.active) {
            background-color: $active-nav-border-light-color;
            outline: none;
        }

        &.selected {
            // Use darker colour to ensure contrast between foreground and background colors meets WCAG 2 AA
            background-color: $active-nav-bg;
            border: 1px solid $active-nav-border-color;
        }

        .ellipsis {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            // .ellipsis cannot be an inline element
        }
    }
}
