// Request header - displays key details on the request and contains the action buttons

.request-header {
  display: flex;
  flex-direction: column; // all items placed vertically till min viewport width is available
  align-items: stretch; // All items have the same height
  margin-top: -20px; // The header is displayed directly below the BS navbar so negate it's bottom margin.

  border-bottom: 1px solid #DDDDDD;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;

  padding: 15px 0;
  background-color: white;
}

// The class "is-stuck" is added by the "stickyElementBinding" when the header is stuck to the top of the viewport
.request-header.is-stuck {
  border: 2px solid #AAA;
  background-color: #FAFAFA;
  box-shadow: 0 5px 6px -3px rgba(182, 182, 182, 0.75);
  transition: all 0.3s;
}

.request-header__item--title {
  // As the header is displayed directly below the BS navbar, use the same padding so content is all in alignment
  padding-left: $navbar-padding-horizontal;
}

.request-header__item--secondary {
  display: none; // Don't show secondary items (like priority and discussion) till viewport hits a minimum size
}

.request-header__divider-button-container {
    display: inline-block;
    margin-right: 5px;
    padding-right: 5px;
}

    .request-header__item--buttons {
        display: flex;
        flex: 1; // Take up the rest of the remaining width
        justify-content: flex-end;
        // As the header is displayed directly below the BS navbar, use the same padding so content is all in alignment
        padding-right: $navbar-padding-horizontal;
    }

    @media screen and (min-width: 630px) {

        .request-header__item--buttons {
            align-self: center;
        }

        .request-header {
            flex-direction: row;
            align-items: stretch; // All items have the same height
        }

        .request-header__item {
            border-right: 1px solid #D8D8D8; // Vertical lines between items
            padding: 0 1em;
        }

        .request-header__item--title {
            padding-right: 2em;
        }

        .request-header__divider-button-container {
            display: inline-block;
            margin-right: 10px;
            border-right: 1px solid #E0E0E0; // Show a vertical line between the "Reject" and "Save" buttons
            padding-right: 15px;
        }
    }

    @media screen and (min-width: 1000px){
        .request-header__item--secondary {
            display: block; // Display the secondary items on larger viewports
        }
    }

    .request-header__title {
        margin: 0;
        font-size: 1.6em;
        font-weight: bold;
    }

    .request-header__label {
        margin-top: 0.2em;
        margin-bottom: 0.3em;
        font-size: 0.9em;
        font-weight: bold;
    }


