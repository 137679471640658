﻿.disable-element-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #FFF;
    opacity: .4;
    z-index: 9999999;
}




