﻿.animate-rotate {
    animation-name: rotate;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @media (prefers-reduced-motion) {
        animation: none;
    }
}

@keyframes rotate {
    from { transform:rotate(0deg); }
    to { transform:rotate(360deg); }
}
