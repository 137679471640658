﻿
.upload-progress-panel {
    position: absolute;
    right: 0;
    width: 400px;

    @media (max-width: $screen-xs-min) {
        width: 325px;
    }


    > div.panel-body {
        max-height: 260px;
        overflow-y: auto;

        > div.fileName {
            float: left;
            max-width: 270px;
            overflow-x: hidden;
            text-overflow: ellipsis;
            margin-right: 5px;
        }

        > div.upload-progress,
          div.upload-status {
            float: left;
        }

        > hr {
            margin-top: 18px;
            margin-bottom: 12px;
        }
    }    
}