
// The lightest colour on a white background that meets WCAG AA colour contrast requirements
$light-text-color: #757575;

// The lightest colour on a grey background
$light-text-color-on-gray: #707070;

// The green used for success / buttons is darker to ensure contrast between foreground and background colors meets WCAG AA
$brand-success: #2C882C !default;

$icon-with-text-color: #888888;
