﻿
.navbar {

    @media (min-width: $grid-float-breakpoint) {
        border-radius: 0;
    }
}

.navbar-nav {

    > li {

        > a {
            padding-left: 10px;
            padding-right: 10px;
            text-transform: uppercase;

            &:hover {
                border-bottom: $active-nav-x-border;
            }

            @media (max-width: $grid-float-breakpoint) {
                border-bottom: 3px solid $navbar-inverse-bg;
            }
        }

        @media (min-width: $grid-float-breakpoint) {
            margin-left: 5px;
            margin-right: 5px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            > a:hover {
                border-bottom-width: 5px;
            }
        }
    }

    > .active > a {
        &,
        &:hover,
        &:focus {
            border-bottom: $active-nav-x-border;

            @media (min-width: $grid-float-breakpoint) {
                border-bottom-width: 5px;
            }
        }
    }

    @media (min-width: $grid-float-breakpoint) {

        > li {
            > a {
                // Allow for the extra pixels for the active item bottom border and the border on the navbar
                padding-top: $navbar-padding-vertical - 3;
                padding-bottom: $navbar-padding-vertical - 4;
            }
        }
    }
}

.navbar-collapse {
    padding-left: $navbar-padding-horizontal - 1; // Allow for 1 pixel navbar border
}
