﻿
.dl-metadata {
    @media (min-width: $dl-horizontal-breakpoint) {
        dt {
            padding-bottom: 10px;
            text-align: left;           
        }
    }
}

// Only on mobile devices, align the text right 
@media (max-width: $screen-xs-min) {
    .text-right-xs {
        text-align: right;
    }
}

