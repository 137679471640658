// Add an extra large 1080p desktop focused responsive helper classes like in Bootstrap 4
@include responsive-invisibility('.visible-xl');

.visible-xl-block,
.visible-xl-inline,
.visible-xl-inline-block {
  display: none !important;
}

@media (min-width: $screen-xl-min) {
  @include responsive-invisibility('.visible-lg');
  @include responsive-visibility('.visible-xl');
}
.visible-xl {
  @media (min-width: $screen-xl-min) {
    display: block !important;
  }
}

@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
  @include responsive-invisibility('.hidden-lg');
}

@media (min-width: $screen-xl-min) {
  @include responsive-invisibility('.hidden-xl');

  .visible-lg {
    display: none !important;
  }
}
