﻿// Ensure the selectize control displays outside of the flow for normal layout
.selectize-control {
    position: static;

    > .form-control {
        height: auto; // Ro fixed 
        word-wrap: break-word;
        word-break: break-word;
        overflow-wrap: break-word;
        -ms-word-break: break-all;
    }
}

