﻿
// Path to Font Awesome webfonts
$fa-font-path: "../node_modules/@fortawesome/fontawesome-pro/webfonts";

// This height allows the user edit form in a normal state (without validation errors) to be displayed without a scroll
$admin-users-tab-filter-area-height: 500px;

$admin-groups-tab-filter-area-height: 644px;

// The height allocated for the filter panel heading and the sort by dropdown so they are aligned
$requests-filter-heading-and-sort-by-min-height: 45px;

// Global variables for an extra large 1080p desktop focused breakpoint like in Bootstrap 4
$screen-xl:                  1260px !default;
$screen-xl-min:              $screen-xl !default;
$screen-xl-desktop:          $screen-xl-min !default;
$screen-lg-max:              ($screen-xl-min - 1) !default;

$default-map-height: 310px;
