
// ----------------------------------------------------------------------------------------------------
// This file should reference SASS files from the folder "site" that introduce new styles.
//
// If you want to alter an existing Bootstrap style THIS IS NOT THE CORRECT PLACE. To alter / override
// an existing Bootstrap style, use "bootstrap-custom.scss".
//
// Custom styles for the site should be added to the folder "site" and referenced in "site.scss".
// ----------------------------------------------------------------------------------------------------

// Bootstrap core variables and mixins
@import "../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

// Site variables, mixins and colours
@import "./site/_variables.scss";
@import "./site/mixins/_fontface.scss";
@import "./site/mixins/_media-queries.scss";
@import "./site/_colours.scss";
@import "./bootstrap-overrides/_variables.scss";

// Font Awesome Pro fonts
@import "../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "../node_modules/@fortawesome/fontawesome-pro/scss/solid.scss";
@import "../node_modules/@fortawesome/fontawesome-pro/scss/regular.scss";

// Site styles (please order files by name alphabetically)
// If you are overriding any existing Bootstrap styles, you MUST use a file in the "bootstrap-overrides" folder
@import "./site/_action-buttons-header.scss";
@import "./site/_animation.scss";
@import "./site/_business-user-details.scss";
@import "./site/_group-details.scss";
@import "./site/_buttons.scss";
@import "./site/_containers.scss";
@import "./site/_disable-element.scss";
@import "./site/_discussion-messaging.scss"; 
@import "./site/_dropdowns.scss";
@import "./site/_duallistbox.scss";
@import "./site/_empty-state.scss";
@import "./site/_file-upload-data.scss";
@import "./site/_file-upload-selector.scss";
@import "./site/_filter-area.scss";
@import "./site/_filter-panel.scss";
@import "./site/_fonts.scss";
@import "./site/_forms.scss";
@import "./site/_grid.scss";
@import "./site/_has-feedback.scss";
@import "./site/_helper-classes.scss";
@import "./site/_loaders.scss";
@import "./site/_map.scss";
@import "./site/_modals.scss";
@import "./site/_navbarbanner.scss";
@import "./site/_navs.scss";
@import "./site/_panels.scss";
@import "./site/_print.scss";
@import "./site/_responsive-utilities.scss";
@import "./site/_spacing.scss";
@import "./site/_tables.scss";
@import "./site/_type.scss";
@import "./site/_upload-progress.scss";

// Request components related styles
@import "./site/requests/request-details";
@import "./site/requests/request-header";
@import "./site/requests/request-list";
@import "./site/requests/requests";

// Vendor styles / vendor style overrides
@import "./site/_vendor.scss";

// Open Layers
@import "../node_modules/ol/ol.css";

