﻿.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.textarea-auto-resize {
    width: 100%;
    min-height: 34px;
    padding: 3px;
    line-height: 24px;
    overflow: hidden;
    resize: none;
}

.wrap-text {
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
    -ms-word-break:  break-all;
}

.text-pre-wrap {
    white-space: pre-wrap;
}

.text-wrap-break-word {
    word-wrap: break-word;

    @include for-ie10plus-only {
        word-break: break-all;
    }

    /* Will be converted to td.text-wrap-break-word CSS selector */
    @at-root td#{&} {
        max-width: 90px;
    }
}

/*
    this class duplicates most of logic from .text-wrap-break-word
    contains workaround to set inline blocks in IE
    is recommended for block styling
*/
.text-wrap-break-word--inline-block {
    word-wrap: break-word;

    @include for-ie10plus-only {
        word-break: break-all;
        display: inline-block;
    }
}

.no-bullet-list {
    list-style-type: none;
    padding-inline-start: 0;
    margin-bottom: 0;
    padding-left: 0;
}

.font-weight-normal {
    font-weight: normal;
}

.font-size-smaller {
    font-size: 0.85em;
}
