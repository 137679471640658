﻿.blank-area {
    text-align: center;
    color: #ABABAB;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20%; // Push the empty state content up slightly from dead vertical center
    i {
        font-size: 70px;
    }

    h4 {
        width: 42%;
        margin: 0 auto;
        // On mobile devices, we need change width of label
        @media (max-width: $screen-xs-min) {
            width: 80%;
        }
    }
}

.blank-area-height-admin-users {
    height: $admin-users-tab-filter-area-height;
}

.blank-area-height-admin-groups {
    height: $admin-groups-tab-filter-area-height;
}
