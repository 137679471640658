// Requests component - displays the filter panel and the request list
.requests {
  display: flex;
  flex-wrap: wrap;
}

// On small viewports, the filter panel and main content areas are shown stacked on top of each other */
.requests__filter-panel,
.requests__main-content {
  width: 100%;
}

.requests__sort-by-container {
  min-height: $requests-filter-heading-and-sort-by-min-height;
}

@media screen and (min-width: $screen-md-min) {

  // On larger viewports, the filter panel and main content areas are displayed side-by-side
  .requests__filter-panel {
    width: 210px;
    margin-right: 20px;
  }

  .requests__main-content {
    flex: 1; // Use up the rest of the remaining width
  }
}

@media screen and (min-width: $screen-xl-min) {
  // On extra large viewports, give the filter panel more width and margin from the main content
  .requests__filter-panel {
    width: 225px;
    margin-right: 45px;
  }
}

.request-list__ellipsis-dropdown-menu {

    .request-list__ellipsis-dropdown-item {
        padding: 3px 5px;
        min-width: 150px;
    }

    .request-list__ellipsis-dropdown-icon {
        min-width: 25px;
        text-align: center;
        display: inline-block;
    }
}