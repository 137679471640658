﻿
ul.pagination li {

    &:first-child > a,
    &:last-child > a {
        border-radius: 0 0 0 0;
    }

    > a {
        padding: 8px 16px; // Allow for a bigger tap target
        border-top: $non-active-nav-x-border;
        border-left: 1px solid $light-nav-border-color;
        border-right: 1px solid $light-nav-border-color;
        border-bottom: none;
    }

    &:first-child > a {
        border-left: none;
    }

    &:last-child > a {
        border-right: none;
    }

    > a:hover,
    > a:focus {
        border-top: $non-active-nav-x-border;
        background-color: #FFF;
        border-left-color: $light-nav-border-color;
        border-right-color: $light-nav-border-color;
    }

    > a:hover {
        border-top: $hover-active-nav-x-border;
    }

    &.active > a,
    &.active > a:hover,
    &.active > a:focus {
        // Use darker colour to ensure contrast between foreground and background colors meets WCAG 2 AA
        color: $link-hover-color; 
        background-color: $active-nav-bg;
        border-top: $active-nav-x-border;
        border-left: 1px solid $light-nav-border-color;
        border-right: 1px solid $light-nav-border-color;
    }

    &:first-child.active > a,
    &:first-child.active > a:hover,
    &:first-child.active > a:focus {
        border-left: none;
    }

    &:last-child.active > a,
    &:last-child.active > a:hover,
    &:last-child.active > a:focus {
        border-right: none;
    }
}