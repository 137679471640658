﻿
.table > tbody > tr:last-child > td {
    border-bottom: 1px solid #ddd; // TODO: Use a variable for this colour
}


// ----------------------------------------------------------------------------------------------------
// Styling used on the request and camera lists pages. The heading is inline on each row.
// ----------------------------------------------------------------------------------------------------
.table-investigate {
    tr > td:first-child,
    tr > th:first-child {
        border-left: 4px solid white;
    }

    tr.highlight > td:first-child {
        border-left: $active-nav-y-border;
    }

    tr {
        > td {
            > div.heading {
                color: #767676;
                font-size: 12px;
            }
        }
    }

    // For web accessibility reasons, ideally all tables have a heading row and this can be visually hidden
    // but does still exist in the DOM so the headings are announced by a screen reader. In this scenario,
    // remove the bottom border so it doesn't interfere with the top border on the first data row (tables
    // use "border-collapse: collapse").
    // If this approach is used with inline headers, they must be hidden from screen readers using the
    // ARIA attribute "aria-hidden: true".
    thead > tr.visually-hidden {
        visibility: collapse;

        > th {
            border-bottom: none;
        }
    }
}

    // ----------------------------------------------------------------------------------------------------
    // Styling used on request upload files editable table. The columns are vertically aligned middle to
    // cater for the input fields which are taller than the text content.
    // ----------------------------------------------------------------------------------------------------
    .table-investigate-edit {
        > tbody {
            > tr {
                > td {
                    vertical-align: middle;
                }
            }
        }
    }



