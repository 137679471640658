﻿$loader-colour: #3498db;

// TODO: Convert the CSS to be more *SCSS* like

img.loader-image {
    @media (prefers-reduced-motion) {
        display: none;
    }
}

// Element used when the results are loading or no results found
.results-status {

    padding: 8px 0 8px 0;

    border-top: 1px solid $hr-border;
    border-bottom: 1px solid $hr-border;

    font-family: RobotoLight, $font-family-sans-serif;
    font-size: 18px;
    font-weight: bold;
}

.cbp-loader {
    margin: 0 auto;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 10px solid transparent;
    border-top-color: darken($loader-colour, 10%);
    -webkit-animation: loader-spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: loader-spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */

    @media (prefers-reduced-motion) {
        display: none;
    }
}

.cbp-loader:before {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    border-radius: 50%;
    border: 10px solid transparent;
    border-top-color: $loader-colour;
    -webkit-animation: loader-spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: loader-spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

.cbp-loader:after {
    content: "";
    position: absolute;
    top: 11px;
    left: 12px;
    right: 11px;
    bottom: 11px;
    border-radius: 50%;
    border: 10px solid transparent;
    border-top-color: lighten($loader-colour, 10%);
    -webkit-animation: loader-spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: loader-spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

@-webkit-keyframes loader-spin {
    0% {
        -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg); /* IE 9 */
        transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
    }

    100% {
        -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg); /* IE 9 */
        transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
    }
}

@keyframes loader-spin {
    0% {
        -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg); /* IE 9 */
        transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
    }

    100% {
        -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg); /* IE 9 */
        transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
    }
}