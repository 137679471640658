﻿.upload-dropdown-menu {
    padding: 0;
}

.dropdown-menu {
    > li.disabled > span {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: normal;
        line-height: $line-height-base;
        color: $dropdown-link-disabled-color;
        white-space: nowrap; // prevent links from randomly breaking onto new lines
        cursor: $cursor-disabled;

        &.version-number {
            color: $light-text-color;
            font-size: 13px;
        }
    }
}

.dropdown-menu-full-width {
    display: inline;
    width: 100%;
}
