﻿.navbarbanner {
    @include container-fixed;
    background-color: $navbar-inverse-bg;

    > h1 {
        float: left;
        margin-top: 9px;
        margin-bottom: 7px;
        padding-left: 0;
        font-family: RobotoLight, $font-family-sans-serif;

        > span {
            float: right;
            margin-left: 4px;
            line-height: 22px;
            color: white;

            &.logo-caption {
                float: left;
                font-size: 12px;
                font-family: $font-family-sans-serif;

                @media (max-device-width: $screen-xs-min) {
                    font-size: 16px;
                }
            }

            > span.logo-subcaption {
                float: right;
                font-size: 12px;
                line-height: 12px;

                @media (max-device-width: $screen-xs-min) {
                    font-size: 9px;
                    line-height: 9px;
                }
            }
        }
    }

    > div.dropdowns-wrapper {
        float: right;

        > div.dropdown {
            float: left;
            margin-top: 14px;
            margin-left: 15px;
            margin-right: 10px;

            > a,
            > .uploading-nav-item__status {
                color: white;

                > span.help-icon {
                    font-size: 16px;
                    margin-top: 2px
                }
            }

            > div {
                z-index: 1000;
            }
        }
    }

    #language-image {
        margin-top: -2px; // Tweak to vertically align the flag icon with it's text
    }
}

.sub-title {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: white;
}
