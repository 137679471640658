﻿.file-upload-selector {
    display: flex; // Ensure each child (file and folder upload options) are displayed next to each other at same height
    // This class is to show a simplified control in Internet Explorer
    .drop-zone-small {
        display: flex;
        flex-direction: row; // Show the title, image and upload button / label as rows
        flex-wrap: wrap; // Show each child item in it's own row
        align-items: flex-end; // Vertically align bottom
        justify-content: center; // Horizontally align center

        width: 160px;
        margin: 10px 10px 10px 0;
        padding: 8px 10px;
        border: 1px solid #CCC;
        border-radius: 5px;
    }

    .drop-zone-large {
        display: flex;
        flex-direction: column; // Show the title, image and upload button / label as columns
        flex-wrap: wrap; // Show each child item in it's own row
        align-items: center; // Horizontally align center
        justify-content: space-between; // Vertically distributes children evenly across entire hight

        width: 350px;
        margin: 10px 10px 10px 0;
        padding: 8px 10px;
        border: 1px solid #CCC;
        border-radius: 5px;
    }

    .drop-zone-title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .drop-zone-title {
        text-align: center;
        font-weight: bold;
        padding: 5px;
    }

    .drop-zone-prompt {
        padding: 5px;
    }

    .drop-zone-image {
        display: flex;
    }

    .drop-zone-button-container {
        display: flex;
        width: 100%;
    }

    .drop-zone-icon {
        height: 55px;
        margin: 5px;
    }

    .drop-zone-folder-icon {
        padding: 3px; // Show the folder icon slightly smaller than the file icon so they then look better / similar size given the icons we are using
    }
    // The label acts as the click target for the file upload input.
    // Display the label and the button at the full available width.
    label {
        display: block; // Ensure the label takes the full width available
        width: 100%;

        input {
            display: none; // Don't show the file upload input as we have a fake upload button
        }

        .btn {
            width: 100%;
        }
    }
}