﻿
.body-container {

    // On mobile devices, don't render the left and right padding on the outer body container
    padding-left: 0;
    padding-right: 0;

    @media (min-width: $screen-sm-min) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.body-container-cookie-disclosure {

    // On mobile devices, don't render the left and right padding on the outer body container
    padding-left: 0;
    padding-right: 0;

    // Allow 40px for the cookie disclosure bar to display at a fixed position at the bottom of the viewport
    padding-bottom: 40px;

    @media (min-width: $screen-sm-min) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

// Page component container with grey border for use on screens that comprise of just a form
.form-container {
    margin-top: -20px;
    padding: 0 20px 20px 20px;
    border: 20px solid #DEDEDE;

    // On mobile devices, don't render the form container border as not enough space
    @media (max-width: $screen-xs-max) {
        margin-top: -5px;
        padding: 0 15px 0 15px;
        border: 0;
    }
}

// Page component container with padding to position content correctly relative to the top navbar
.content-container {
    margin-top: -5px;
    padding: 0 15px 0 15px;
}

.footer-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    text-align: center;
    background: #F5F5F5;
    border-top: 1px #ddd solid;
}



