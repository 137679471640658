﻿.lead {
    font-family: $headings-font-family;
}

.text-danger {
    color: #D42221;
    font-weight: bold;
}

.bg-success{
    background-color: #23E05C;
}
.text-success{
    color: #23E05C;
}
