﻿
// Style the panel for use on the reset password screens
.panel-reset-password {

    margin-bottom: 10px;

    > .panel-body {
        padding-bottom: 22px;

        div > h3 {
            margin-top: 0;
            margin-bottom: 15px;
        }
    }
}
