﻿
ul.left-menu > li {
    
    margin: 0;

    a {
        border-left: $non-active-nav-y-border;
        border-bottom: 1px solid $light-nav-border-color;
        border-radius: 0;
    }

    a:hover,
    a:focus {
        background-color: #FFF;        
    }

    a:hover {
        border-left: $hover-active-nav-y-border;
    }

    a.child {
        padding-left: 30px;
    }

    &:last-child > a {
        border-bottom: none;        
    }

    &.active > a,
    &.active > a:hover,
    &.active > a:focus {
        // Use darker colour to ensure contrast between foreground and background colors meets WCAG 2 AA
        color: $link-hover-color; 
        background-color: $active-nav-bg;
        border-left: $active-nav-y-border;
    }
     
}

.navbar-nav--always-inline {
    float: left;
    margin: 0;
}

.navbar-nav--always-inline > li {
    float: left;
    margin-left: 5px;
    margin-right: 5px;
}

.navbar-nav--always-inline > li:first-child {
    margin-left: 0;
}

.navbar-nav > li > a {
    border-bottom: none;
}

.navbar-nav--always-inline > .active > a,
.navbar-nav--always-inline > .active > a:hover,
.navbar-nav--always-inline > .active > a:focus {
    border-bottom-width: 5px;
}