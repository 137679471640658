﻿// Local variables for an extra large 1080p desktop focused breakpoint like in Bootstrap 4
@use "sass:math";

$container-xlarge-desktop:   ($screen-xl - $grid-gutter-width) !default;
$container-xl:               $container-xlarge-desktop !default;


// Add an extra large 1080p desktop focused breakpoint like in Bootstrap 4
@include make-grid-columns;

.container {
    @media (min-width: $screen-xl-min) {
        width: $container-xl;
    }
}

// XLarge grid
// Columns, offsets, pushes, and pulls for the large desktop device range.
@media (min-width: $screen-xl-min) {
    @include make-grid(xl);
}

// Generate the XLarge columns
@mixin make-xl-column($columns, $gutter: $grid-gutter-width) {
    position: relative;
    min-height: 1px;
    padding-left:  ($gutter * 0.5);
    padding-right: ($gutter * 0.5);

    @media (min-width: $screen-xl-min) {
        float: left;
        width: percentage(math.div($columns, $grid-columns));
    }
}
@mixin make-xl-column-offset($columns) {
    @media (min-width: $screen-xl-min) {
        margin-left: percentage(math.div($columns, $grid-columns));
    }
}
@mixin make-xl-column-push($columns) {
    @media (min-width: $screen-xl-min) {
        left: percentage(math.div($columns, $grid-columns));
    }
}
@mixin make-xl-column-pull($columns) {
    @media (min-width: $screen-xl-min) {
        right: percentage(math.div($columns, $grid-columns));
    }
}

@mixin make-grid-columns($i: 1, $list: ".col-xl-#{$i}") {
    @for $i from (1 + 1) through $grid-columns {
        $list: "#{$list}, .col-xl-#{$i}";
    }
    #{$list} {
        position: relative;
        // Prevent columns from collapsing when empty
        min-height: 1px;
        // Inner gutter via padding
        padding-left:  ($grid-gutter-width * 0.5);
        padding-right: ($grid-gutter-width * 0.5);
    }
}

// ----------------------------------------------------------------------------------------------------

.row-margin-bottom {
    margin-bottom: 10px;
}

// When columns are stacked in mobile mode, add a bottom margin
@media (max-width: $screen-xs-min) {
    .col-xs-margin-bottom {
        margin-bottom: 15px;
    }
}

// When columns are stacked in mobile mode, add a top margin
@media (max-width: $screen-xs-min) {
    .col-xs-margin-top {
        margin-top: 15px;
    }
}

